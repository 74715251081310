// @mui
import { Card, CardContent, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
//

// ----------------------------------------------------------------------

const RootStyle = styled(Card)(({ theme }) => ({
  boxShadow: 'none',
  textAlign: 'center',
  backgroundColor: theme.palette.primary.lighter,
  [theme.breakpoints.up('md')]: {
    height: '100%',
    display: 'flex',
    textAlign: 'left',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
}));

// ----------------------------------------------------------------------

export default function Welcome() {
  return (
    <RootStyle>
      <CardContent
        sx={{
          color: 'grey.800',
          p: { md: 2 },
          pl: { md: 5 }
        }}
      >
        <Typography gutterBottom variant="h4">
          Cộng đồng,
          <br /> PHÁP HỌC
        </Typography>
        <Typography variant="body2" sx={{ pb: { xs: 1, xl: 2 }, maxWidth: 480, mx: 'auto', mt: 2 }}>
          Nguồn ánh sáng soi đường cho chúng ta hiểu rõ bản thân, cuộc sống, và thế giới xung quanh để sống an vui, hạnh phúc, và đạt được giác ngộ.
        </Typography>
      </CardContent>
    </RootStyle>
  );
}
