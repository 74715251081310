/* eslint-disable react/prop-types */
// @mui
// @mui
import { Container, Grid, Link, Stack, Typography } from '@mui/material';
import Layout from '../layouts';
// components
import Page from '../components/Page';
// sections
import dynamic from 'next/dynamic';
import NextLink from 'next/link';
import useLocales from '../hooks/useLocales';
import useSettings from '../hooks/useSettings';
import Welcome from '../sections/home/Welcome';
import { findSliderAPI } from '../service/audiobook/audiobook.slider.service';
const HomeSlider = dynamic(() => import('../sections/home/HomeBookSlider'), { ssr: false });
const HomeCategories = dynamic(() => import('../sections/home/HomeCategories'), { ssr: false });
const BannerSlider = dynamic(() => import('../sections/home/BannerSlider'), { ssr: false });
const HomePosts = dynamic(() => import('../sections/home/HomePosts'), { ssr: false });

HomePage.getLayout = function getLayout(page) {
  return <Layout variant="main">{page}</Layout>;
};

// ----------------------------------------------------------------------

export default function HomePage({ sliders }) {
  const { translate } = useLocales();
  const { themeStretch } = useSettings();

  return (
    <Page title={translate('index.title')} description={translate('index.description')}>
      <Container maxWidth={themeStretch ? 'xl' : 'lg'}>
        <BannerSection sliders={sliders} />
      </Container>
    </Page>
  );
}

function BannerSection({ sliders }) {
  const { translate } = useLocales();

  return (
    <Grid container spacing={5} mb={6}>
      <Grid item xs={12} md={4}>
        <Welcome />
      </Grid>
      <Grid item xs={12} md={8}>
        <BannerSlider sliders={sliders} />
      </Grid>
      <Grid item xs={12} md={12}>
        <Stack spacing={4}>
          <Link href={"/books/"} color={"inherit"} component={NextLink}>
            <Typography variant='subtitle1' sx={{ textDecoration: 'underline', cursor: 'pointer' }}>{translate("menu.book")}</Typography>
          </Link>
          <HomeCategories />
          <HomeSlider />
        </Stack>
      </Grid>
      <Grid item xs={12} md={12}>
        <Stack spacing={4}>
          <Link href={"/blogs/"} color={"inherit"} component={NextLink}>
            <Typography variant='subtitle1' sx={{ textDecoration: 'underline', cursor: 'pointer' }}>{translate("menu.blog")}</Typography>
          </Link>
          <HomePosts />
        </Stack>
      </Grid>
    </Grid>
  );
}


export async function getStaticProps() {
  const search = {
    page: 0, size: 20, value: '%%', orders: [{ order: 'asc', property: 'id' }], filterBys: { status: 'ACTIVE' }
  };
  const { code, data: sliders } = await findSliderAPI(search);

  if (code !== '200') {
    return {
      props: {
        sliders: []
      },
    };
  }

  return {
    props: {
      sliders,
    },
  };
}

